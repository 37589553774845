<template>
    <div>
        <b-card no-body class="mb-0 p-1">
          
            <h1>Personal Infomation</h1>
              <b-row>
                <b-col  cols="12"
          md="8">
                  <div id="profile-info"><h1>info</h1></div>
                 
          </b-col>
        
                <b-col  cols="12"
          md="4">
                <div id="avatar"><h1>avatar</h1></div>
          </b-col>
             

            </b-row>
        </b-card>
    </div>
</template>
<script>
import {
    BRow,
    BCol,
  BCard,
  BButton,
  BTable,
  BPagination,
  BFormCheckbox,
  BFormSelect
} from "bootstrap-vue";
export default {
    components :{
        BRow,
        BCol,
          BFormSelect,
    BFormCheckbox,
    BButton,
    BTable,
    BPagination,
    BCard,
    }
    
}
</script>
<style>
    
</style>